import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "use-with-auth0"
    }}>{`Use with Auth0`}</h1>
    <p><inlineCode parentName="p">{`useAuth`}</inlineCode>{` was originally created with Auth0 in mind. It uses the `}<a parentName="p" {...{
        "href": "https://github.com/auth0/auth0.js"
      }}><inlineCode parentName="a">{`auth0-js`}</inlineCode></a>{` library under the hood and supports all the same configuration.`}</p>
    <p>{`example app 👉 `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useAuth/tree/master/examples/useauth-gatsby-auth0"
      }}>{`examples/useauth-gatsby-auth0`}</a></p>
    <h2 {...{
      "id": "1-create-a-new-application-on-auth0"
    }}>{`1. Create a new application on Auth0`}</h2>
    <p>{`Go to your `}<a parentName="p" {...{
        "href": "manage.auth0.com/dashboard"
      }}>{`Auth0 Dashboard`}</a>{`, find the tenant you wish to use, and create a new Single Page Application.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9f8ad77ffb437724363e233ea6ac79c5/10c1e/auth0-application.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "82.51121076233184%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'330\\'%20viewBox=\\'0%200%20400%20330\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M206%2016c0%201-1%202-6%201-5%200-6%200-6%202s4%203%206%202h1l2%201%207-1c5%200%206-1%206-2h1c0%203%209%202%209%200l1%201c1%201%201%201%201-1s0-2-4-2c-3%201-4%200-4-1h-1l-3%201-3%201-1%201v-1l-1-1c-1%201-2%200-2-1l-1%201-1%202v-2l-1-1m181%20136c0%2081%201%20124%202%20124l1-124-1-124c-2%200-2%2014-2%20124m-277-27c-1%202-2%20146%200%20148l42%201h42V125l-42-1-42%201m1%2075v73h82V126h-82v74m-54-52c-2%202-3%204-3%207v5h5c4%200%205%200%206-2l2-3%201-5v-5h-4c-4%200-5%201-7%203m89%201c-1%204-1%204%201%208%203%205%206%205%209%200l3-4-2-4c-3-6-8-6-11%200m91-2l-2%206c0%202%204%207%207%207%202%200%207-3%207-5%202-4-2-10-6-10l-6%202m-92%2039c0%201-1%202-4%201l-4%201c0%201-1%201-1-1-2-2-2-2-3%202-1%201-1%202%201%201l2%201h7l12-1h12c3%201%204%201%204-1s-1-2-6-2a685%20685%200%2001-20-1m90%200l-4%201-4%201h-1c0-3-2-2-3%201l1%202h16c5%200%206%200%206-2h1c0%203%208%202%209%200h1c0%202%204%202%204%201v-2l-5-1-6-1h-1l-3%201-3%201-2%201v-1c1-1%201-1-1-1l-3-1h-2m79%209l-1%202c0%202%200%202%201%201l3%201c1%202%202%201%203%200h2c0%202%200%202%201%201l12-1%2011-1h1l3%201c1%200%202%200%202-2l-7-1-5-1c1-1%201-1-1-1l-2%201h-15c-3%200-4%200-4%202l-1-1c-1-2-3-3-3-1m-161%2097l-1%2010v10h46v-20l-23-1-22%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/9f8ad77ffb437724363e233ea6ac79c5/ca0a1/auth0-application.webp 223w", "/static/9f8ad77ffb437724363e233ea6ac79c5/75680/auth0-application.webp 445w", "/static/9f8ad77ffb437724363e233ea6ac79c5/8d1ba/auth0-application.webp 890w", "/static/9f8ad77ffb437724363e233ea6ac79c5/3838e/auth0-application.webp 1335w", "/static/9f8ad77ffb437724363e233ea6ac79c5/e11e5/auth0-application.webp 1780w", "/static/9f8ad77ffb437724363e233ea6ac79c5/e91b7/auth0-application.webp 2090w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/9f8ad77ffb437724363e233ea6ac79c5/e92b6/auth0-application.png 223w", "/static/9f8ad77ffb437724363e233ea6ac79c5/e66bf/auth0-application.png 445w", "/static/9f8ad77ffb437724363e233ea6ac79c5/4ef49/auth0-application.png 890w", "/static/9f8ad77ffb437724363e233ea6ac79c5/4e814/auth0-application.png 1335w", "/static/9f8ad77ffb437724363e233ea6ac79c5/701e9/auth0-application.png 1780w", "/static/9f8ad77ffb437724363e233ea6ac79c5/10c1e/auth0-application.png 2090w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/9f8ad77ffb437724363e233ea6ac79c5/4ef49/auth0-application.png",
                "alt": "Auth0 Create application UI",
                "title": "Auth0 Create application UI",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 Create application UI`}</figcaption>{`
  `}</figure></p>
    <p>{`Once created, go to settings and find your Domain and ClientID. You'll need them to configure `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/10db494c57c587888f7734ae018d6377/3707e/auth0-settings.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.399103139013455%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'230\\'%20viewBox=\\'0%200%20400%20230\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M62%2044c0%202%200%202-1%201h-5l-1%202h-1c-1-2-9-3-9-1h-2c-1-2-4%200-3%202s6%201%206%200h6c0%202%205%202%206-1h1l1%202%201-1v-1l1%201%201%201h6l1-1h2c-1-3-6-4-8-2l-1-1-1-1m97%2078c0%202%200%202-1%200%200-2-3-2-3%201h-1l-5-1h-1c-2-1-9-1-9%201h-1c-1-2-1-2-1%200l2%202%201-1%201-1%201%201h15l2%201%201-1h1c1%202%2016%201%2016-1h1c1%202%205%202%206%201%201-3-2-6-4-4-1%202-1%202-2%200-2-1-2-1-2%201l-1%203v-1c1-3-2-3-3-1l-2%201c0-2-4-2-5%200-1%201-1%201-3-1l-3-3v3\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/10db494c57c587888f7734ae018d6377/ca0a1/auth0-settings.webp 223w", "/static/10db494c57c587888f7734ae018d6377/75680/auth0-settings.webp 445w", "/static/10db494c57c587888f7734ae018d6377/8d1ba/auth0-settings.webp 890w", "/static/10db494c57c587888f7734ae018d6377/3838e/auth0-settings.webp 1335w", "/static/10db494c57c587888f7734ae018d6377/e11e5/auth0-settings.webp 1780w", "/static/10db494c57c587888f7734ae018d6377/6c20f/auth0-settings.webp 1878w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/10db494c57c587888f7734ae018d6377/e92b6/auth0-settings.png 223w", "/static/10db494c57c587888f7734ae018d6377/e66bf/auth0-settings.png 445w", "/static/10db494c57c587888f7734ae018d6377/4ef49/auth0-settings.png 890w", "/static/10db494c57c587888f7734ae018d6377/4e814/auth0-settings.png 1335w", "/static/10db494c57c587888f7734ae018d6377/701e9/auth0-settings.png 1780w", "/static/10db494c57c587888f7734ae018d6377/3707e/auth0-settings.png 1878w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/10db494c57c587888f7734ae018d6377/4ef49/auth0-settings.png",
                "alt": "Auth0 settings",
                "title": "Auth0 settings",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 settings`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "2-configure-your-auth0-app"
    }}>{`2. Configure your Auth0 app`}</h2>
    <p>{`Auth0 is strict about which websites can and can't initiate a login flow for your users. This ensures your users can't fall prey to hacking attempts and get their auth stolen.`}</p>
    <p>{`Wouldn't want some other website to pretend they're you 😉`}</p>
    <p>{`Scroll down in settings and configure:`}</p>
    <ol>
      <li parentName="ol">{`your allowed Callback URLs`}</li>
      <li parentName="ol">{`your allowed Logout URLs`}</li>
      <li parentName="ol">{`your allowed Web Origins`}</li>
    </ol>
    <p><inlineCode parentName="p">{`useAuth`}</inlineCode>{` uses the `}<inlineCode parentName="p">{`<domain>/auth0_callback`}</inlineCode>{` callback URL and the `}<inlineCode parentName="p">{`<domain>/`}</inlineCode>{` logout url by default. You can change this behavior, but make sure it matches your Auth0 config.`}</p>
    <p>{`Here's what the `}<inlineCode parentName="p">{`useAuth.dev`}</inlineCode>{` example site uses:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/003a5b14f34db6a74d91c603fcfaf0de/c4842/auth0-callback-url.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.869955156950674%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'91\\'%20viewBox=\\'0%200%20400%2091\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M115%208v39l1%2038h245V8L238%207%20115%208m1%201v38l1%2036h243V9L238%208%20116%209M38%2019c-2%201-3%202-4%201H19c0-3-4-2-4%201v3l-1-3c-1-4-3-4-4%200l-1%202%208%201%207-1h7l2%201%202-1h1l2%201c2%200%202-1%202-3%200-5%200-4-2-2\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/003a5b14f34db6a74d91c603fcfaf0de/ca0a1/auth0-callback-url.webp 223w", "/static/003a5b14f34db6a74d91c603fcfaf0de/75680/auth0-callback-url.webp 445w", "/static/003a5b14f34db6a74d91c603fcfaf0de/8d1ba/auth0-callback-url.webp 890w", "/static/003a5b14f34db6a74d91c603fcfaf0de/3838e/auth0-callback-url.webp 1335w", "/static/003a5b14f34db6a74d91c603fcfaf0de/09fed/auth0-callback-url.webp 1634w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/003a5b14f34db6a74d91c603fcfaf0de/e92b6/auth0-callback-url.png 223w", "/static/003a5b14f34db6a74d91c603fcfaf0de/e66bf/auth0-callback-url.png 445w", "/static/003a5b14f34db6a74d91c603fcfaf0de/4ef49/auth0-callback-url.png 890w", "/static/003a5b14f34db6a74d91c603fcfaf0de/4e814/auth0-callback-url.png 1335w", "/static/003a5b14f34db6a74d91c603fcfaf0de/c4842/auth0-callback-url.png 1634w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/003a5b14f34db6a74d91c603fcfaf0de/4ef49/auth0-callback-url.png",
                "alt": "Auth0 callback URLs",
                "title": "Auth0 callback URLs",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 callback URLs`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2c97ac427a7b3801c23d6a7c59dc433f/17d12/auth0-logout-url.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "17.040358744394617%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'69\\'%20viewBox=\\'0%200%20400%2069\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M19%2020v4l-1-3c-1-4-3-4-4%200l-1%202h11c0%202%203%201%204-1h1c0%202%202%203%202%201h1c0%202%202%201%202-1h1c0%202%204%203%204%201h1l2%201c2%200%202-1%202-4v-3l-2%202c-2%201-3%202-4%201H24c-1%201-1%201-1-1%200-3-4-2-4%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/2c97ac427a7b3801c23d6a7c59dc433f/ca0a1/auth0-logout-url.webp 223w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/75680/auth0-logout-url.webp 445w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/8d1ba/auth0-logout-url.webp 890w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/3838e/auth0-logout-url.webp 1335w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/b6d17/auth0-logout-url.webp 1666w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/2c97ac427a7b3801c23d6a7c59dc433f/e92b6/auth0-logout-url.png 223w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/e66bf/auth0-logout-url.png 445w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/4ef49/auth0-logout-url.png 890w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/4e814/auth0-logout-url.png 1335w", "/static/2c97ac427a7b3801c23d6a7c59dc433f/17d12/auth0-logout-url.png 1666w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2c97ac427a7b3801c23d6a7c59dc433f/4ef49/auth0-logout-url.png",
                "alt": "Auth0 logout URLs",
                "title": "Auth0 logout URLs",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 logout URLs`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a35b685436b2b5b9d919e072e3e66c39/1e5d2/auth0-web-origins.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "17.48878923766816%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'71\\'%20viewBox=\\'0%200%20400%2071\\'%20preserveAspectRatio=\\'none\\'/%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a35b685436b2b5b9d919e072e3e66c39/ca0a1/auth0-web-origins.webp 223w", "/static/a35b685436b2b5b9d919e072e3e66c39/75680/auth0-web-origins.webp 445w", "/static/a35b685436b2b5b9d919e072e3e66c39/8d1ba/auth0-web-origins.webp 890w", "/static/a35b685436b2b5b9d919e072e3e66c39/3838e/auth0-web-origins.webp 1335w", "/static/a35b685436b2b5b9d919e072e3e66c39/ba360/auth0-web-origins.webp 1630w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/a35b685436b2b5b9d919e072e3e66c39/e92b6/auth0-web-origins.png 223w", "/static/a35b685436b2b5b9d919e072e3e66c39/e66bf/auth0-web-origins.png 445w", "/static/a35b685436b2b5b9d919e072e3e66c39/4ef49/auth0-web-origins.png 890w", "/static/a35b685436b2b5b9d919e072e3e66c39/4e814/auth0-web-origins.png 1335w", "/static/a35b685436b2b5b9d919e072e3e66c39/1e5d2/auth0-web-origins.png 1630w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/a35b685436b2b5b9d919e072e3e66c39/4ef49/auth0-web-origins.png",
                "alt": "Auth0 web origins",
                "title": "Auth0 web origins",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 web origins`}</figcaption>{`
  `}</figure></p>
    <p>{`Note that localhost is added to enable local testing. ✌️`}</p>
    <h2 {...{
      "id": "3-install-dependencies"
    }}>{`3. Install dependencies`}</h2>
    <p>{`Install both useAuth and auth0-js. We put providers in peer dependencies to reduce package sizes :)`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add react-use-auth auth0-js
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install react-use-auth auth0-js
`}</code></pre>
    <h2 {...{
      "id": "4-configure-useauth"
    }}>{`4. Configure useAuth`}</h2>
    <p>{`You can configure `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` itself and the underlying Auth0 client in one go. Configuration with sane defaults needs just 2 params:`}</p>
    <ol>
      <li parentName="ol">{`your domain`}</li>
      <li parentName="ol">{`your clientID`}</li>
    </ol>
    <h3 {...{
      "id": "with-gatsby"
    }}>{`With Gatsby`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// gatsby-browser.js

import { AuthConfig } from "react-use-auth";
import { Auth0 } from "react-use-auth/auth0";
import { navigate } from "gatsby";

export const wrapPageElement = ({ element }) => (
    <>
        <AuthConfig
            authProvider={Auth0}
            navigate={navigate}
            params={{
                domain: "useauth.auth0.com",
                clientID: "GjWNFNOHqlino7lQNjBwEywalaYtbIzh"
            }}
        />
        {element}
    </>
);
`}</code></pre>
    <h3 {...{
      "id": "with-nextjs"
    }}>{`With NextJS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// pages/_app.js

import { AuthConfig } from "react-use-auth";
import { Auth0 } from "react-use-auth/auth0";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
    const router = useRouter();

    return (
        <>
            <AuthConfig
                authProvider={Auth0}
                navigate={(url) => router.push(url)}
                params={{
                    domain: "useauth.auth0.com",
                    clientID: "GjWNFNOHqlino7lQNjBwEywalaYtbIzh"
                }}
            />
            <Component {...pageProps} />
        </>
    );
}
`}</code></pre>
    <p><em parentName="p">{`PS: domain and clientID in snippets are from the useAuth demo site and are shared intentionally so you can test. Replace with your own values`}</em></p>
    <h3 {...{
      "id": "default-auth0-params"
    }}>{`Default Auth0 params`}</h3>
    <p>{`By default `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{`'s Auth0 client uses these params:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// callback domain is current window.location or localhost:8000
const callbackDomain =
    typeof window !== "undefined"
        ? \`\${window.location.protocol}//\${window.location.host}\`
        : "http://localhost:8000";

// default params
const params = {
    domain,
    clientID,
    redirectUri: \`\${callbackDomain}/auth0_callback\`,
    audience: \`https://\${callbackDomain}/api/v2/\`,
    responseType: "token id_token",
    scope: "openid profile email"
};
`}</code></pre>
    <p><inlineCode parentName="p">{`domain`}</inlineCode>{` and `}<inlineCode parentName="p">{`clientID`}</inlineCode>{` come from your props.`}</p>
    <p><inlineCode parentName="p">{`redirectUri`}</inlineCode>{` is set to use the `}<inlineCode parentName="p">{`auth0_callback`}</inlineCode>{` on the current domain. Auth0 redirects here after users login so you can set cookies and stuff. useAuth handles this for you.`}</p>
    <p><inlineCode parentName="p">{`audience`}</inlineCode>{` is set to use api/v2. I know this is necessary but have been copypasting it through several projects.`}</p>
    <p><inlineCode parentName="p">{`responseType`}</inlineCode>{` same here. I copy paste this from old projects so I figured it's a good default.`}</p>
    <p><inlineCode parentName="p">{`scope`}</inlineCode>{` you need openid for social logins and to be able to fetch user profiles after authentication. Profile and Email too.`}</p>
    <p>{`You can over-ride these defaults through the `}<inlineCode parentName="p">{`params`}</inlineCode>{` prop of `}<inlineCode parentName="p">{`<AuthConfig>`}</inlineCode>{`. Params are passed through to the Auth0 client so you can pass any param that `}<inlineCode parentName="p">{`Auth0.WebAuth`}</inlineCode>{` accepts.`}</p>
    <h2 {...{
      "id": "5-create-the-callback-page"
    }}>{`5. Create the callback page`}</h2>
    <p>{`Auth0 is based on OAuth and requires redirecting your user to Auth0's login form. After login, users are redirected back to your app.`}</p>
    <p>{`Any way of creating React pages should work, here's the code for Gatsby:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from "react"
import { useAuth } from "react-use-auth"

const Auth0CallbackPage = () = {
    // this is the important part
    const { handleAuthentication } = useAuth()
    React.useEffect(() => {
        handleAuthentication()
    }, [handleAuthentication])
    // 👆

    return (
        <h1>
            This is the auth callback page,
            you should be redirected immediately!
        </h1>
    )
}

export default Auth0CallbackPage
`}</code></pre>
    <p>{`The goal is to load a page, briefly show some text, and run the `}<inlineCode parentName="p">{`handleAuthentication`}</inlineCode>{` method from `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` on page load.`}</p>
    <p>{`That method creates a cookie in local storage with your user's information and redirects back to homepage. You can pass a `}<inlineCode parentName="p">{`postLoginRoute`}</inlineCode>{` param to redirect to a different page.`}</p>
    <p><strong parentName="p">{`Make sure you add `}<inlineCode parentName="strong">{`<domain>/auth0_callback`}</inlineCode>{` as a valid callback URL in your Auth0 config.`}</strong></p>
    <p>{`You can change this URL by passing a custom `}<inlineCode parentName="p">{`redirectUri`}</inlineCode>{` param to `}<inlineCode parentName="p">{`<AuthConfig />`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "6-enjoy-"
    }}>{`6. Enjoy 😊`}</h2>
    <p>{`You're ready to use `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` anywhere on your site. Check the `}<a parentName="p" {...{
        "href": "/docs/api-reference"
      }}>{`API Reference`}</a>{` for more detail.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Login = () => {
    const { isAuthenticated, login, logout } = useAuth();

    if (isAuthenticated()) {
        return <button onClick={logout}>Logout</Button>;
    } else {
        return <button onClick={login}>Login</Button>;
    }
};
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/auth0-flow.gif",
        "alt": "useAuth flow with Auth0"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      